import type { ObjectWithKey } from './KeyedObject';

export enum ToastType {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Dark = 'dark',
}

export interface ToastMessage {
    message: string;
    type: ToastType;
    details?: string;
    customAction?: () => void;
    customButtonText?: string;
    hideClose?: boolean;
}

export interface ToastMessageWithKey extends ObjectWithKey, ToastMessage {}
